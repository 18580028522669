import React from "react";

function Header() {
  return (
    <header className="tc-inner-header-style1 pb-100">
      <div className="container">
        <div className="info">
          <h1 className="js-title"> Qui sommes-nous ? </h1>
          <div className="text fsz-20 color-666">
            BOUKLAB architecture est une agence d’architecture spécialisée dans
            les projets pour particuliers. Notre expertise couvre principalement
            l’habitat individuel, collectif et les commerces. Grâce à une
            méthodologie rigoureuse, nous anticipons les attentes et détectons
            les besoins de nos clients pour offrir des solutions esthétiques,
            pratiques et techniques, tout en respectant leur budget et le
            contexte du projet. Nous nous engageons à être proches et à l’écoute
            de nos clients, garantissant le respect du programme et des
            contraintes budgétaires. Avec passion et savoir-faire, BOUKLAB
            architecture aspire à surprendre, à faire rêver, et à créer des
            espaces de vie de qualité.
          </div>
          <h1 className="js-title mb-n5"> Où nous trouver ? </h1>
        </div>
      </div>
    </header>
  );
}

export default Header;
