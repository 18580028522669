import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Home from './pages';
import Home1 from './pages/home1';
import AboutPage from './pages/innerpages/about';
import BlogPage from './pages/innerpages/blog';
import ContactPage from './pages/innerpages/contact';
import PortfolioPage from './pages/innerpages/portfolio';
import ServicesPage from './pages/innerpages/services';
import SinglePost from './pages/innerpages/single_post';
import SingleProject from './pages/innerpages/single_project';
import SingleProject2 from './pages/innerpages/single_project2';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home1 />} />
        {/* <Route path="/home1" element={<Home1 />} /> */}
        <Route path="/a propos" element={<AboutPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/portfolio" element={<PortfolioPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/single_post" element={<SinglePost />} />
        <Route path="/single_project" element={<SingleProject />} />
        <Route path="/single_project2" element={<SingleProject2 />}
        />
      </Routes>
    </Router>
  );
}

export default App;
