import React from 'react';

function Footer() {
  return (
    <footer className="tc-footer-style1">
      <div className="container">
        <div className="top-content section-padding">
          <div className="row gx-0">
            <div className="col-lg-4">
              <div className="info-side">
                <div className="text fsz-18 color-333 lh-3 fw-600">
                  Nous croyons que l'architecture a le pouvoir de façonner des vies et d'embellir les communautés. La philosophie architecturale de Bouklab se caractérise par la passion pour l'innovation, la durabilité et l'esthétique intemporelle
                </div>
              </div>
            </div>
            <div className="col-lg-4 offset-lg-4">
              <div className="branch-card">
                <h5 className="mb-20 mt-5 mt-lg-0 fw-600"> Nous contacter</h5>
                <ul className="footer-links">
                  <li>
                    <a href="https://maps.app.goo.gl/8ZUzwMSSSP37EyYC7">03 Rue Lavoisier, 93500 Pantin</a>
                  </li>
                  <li>
                    <a href="mailto:contact@bouklab-architecte.fr"> contact@bouklab-architecte.fr </a>
                  </li>
                  <li>
                    <p> 06 41 99 26 85 </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="foot">
          <div className="row">
            <div className="col-lg-6">
              <p className="fsz-13">© 2024 Bouklab Architecture. Tous droits reservés</p>
            </div>
            <div className="col-lg-6">
              <div className="foot-links mt-4 mt-lg-0">
                <a href="/"> Accueil </a>
                <a href="/Services"> Services </a>
                <a href="/Projets"> Projets </a>
                <a href="/Contact"> Contact </a>
                <a href="/A propos"> A propos </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
