import React from 'react';

function Header() {
  return (
    <header className="tc-header-preview">
      <div className="slider-card">
        <div className="img">
          <img
            src="/innerpages/assets/img/about/header3.jpg"
            alt=""
            className="img-cover"
          />
        </div>
        <div className="info">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 offset-lg-6">
                <div className="rotate-box" data-speed="1" data-lag="0.4">
                  {/* <a
                    href="#"
                    className="rotate-circle fsz-35 rotate-text d-inline-block text-uppercase"
                  >
                    <svg className="textcircle" viewBox="0 0 500 500">
                      <defs>
                        <path
                          id="textcircle"
                          d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                        ></path>
                      </defs>
                      <text>
                        <textPath xlinkHref="#textcircle" textLength="900">
                          • ans d'experience • depuis 2012
                        </textPath>
                      </text>
                    </svg>
                  </a> */}
                  {/* <span className="num"> 12 </span> */}
                </div>
              </div>
            </div>
            <div className="row ps-lg-5">
              <div className="col-lg-12">
              <h1 style={{marginBottom:"200px"}}> Notre vision </h1>              
              </div>
            </div>
            <ul className="features-txt ps-lg-5">
              <li> Architecture </li>
              <li> Design d'intérieur </li>
              <li> Modélisation 3D & Animation </li>
              <li> Decoration & équipement </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
