import React from 'react';

function Map() {
  return (
    <section className="tc-map-style1">
      <div className="map-card wow zoomIn slow pt-0" data-wow-delay="0.2s">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2623.3684722857947!2d2.415286076779317!3d48.88931449852929!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66d7ec5b33d35%3A0xa2035e1d1f325855!2sBOUKLAB%20Abdelmadjid%20architecte!5e0!3m2!1sfr!2sfr!4v1712669232794!5m2!1sfr!2sfr"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullscreen=""
          loading="lazy"
          title="title"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </section>
  );
}

export default Map;
