import React from 'react';

function About() {
  return (
    <section className="tc-about-style7">
      <div className="container">
        <div className="row gx-0 justify-content-between">
          <div className="col-lg-3">
            <div className="rotate-box" data-speed="1" data-lag="0.4">
              <a
                href="/"
                className="rotate-circle fsz-30 rotate-text d-inline-block text-uppercase"
              >
                <svg className="textcircle" viewBox="0 0 500 500">
                  <defs>
                    <path
                      id="textcircle"
                      d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                    ></path>
                  </defs>
                  <text>
                    <textPath xlinkHref="#textcircle" textLength="2500">
                      Design d'intérieur  Architecture 
                    </textPath>
                  </text>
                </svg>
              </a>
              <img
                src="/innerpages/assets/img/leafs.png"
                alt=""
                className="icon"
              />
            </div>
          </div>
          <div className="col-lg-5">
            <div className="info wow fadeInUp slow" data-wow-delay="0.2s">
              <div className="text fsz-25 fw-500 mb-20">
              BOUKLAB Architecture est une agence expérimentée spécialisée dans l'habitat individuel, collectif et les commerces. Notre approche méthodique nous permet de comprendre les besoins de nos clients pour fournir des solutions esthétiques, pratiques et techniques, tout en respectant les contraintes budgétaires et contextuelles. Notre objectif est de créer des espaces de vie de haute qualité en respectant scrupuleusement le programme, le budget et les spécificités de chaque projet.
              </div>
              <div className="text fsz-25 fw-500 mb-20">
              Notre objectif est d'offrir à nos clients des espaces de vie équilibrés, conjuguant confort et esthétique.
              </div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="numbers mt-5 mt-lg-0">
              <div
                className="number-card wow zoomIn slow"
                data-wow-delay="0.4s"
              >
                <h2 className="numb"> 95% </h2>
                <small> Clients de satisfaits </small>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
