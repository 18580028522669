import React from 'react';

function Chat() {
  return (
    <section className="tc-chat-style1">
      <div className="container">
        <div className="content">
          <a href="mailto:contact@bouklab-architecte.fr" className="xl-text">
          contact@bouklab-architecte.fr
          </a>
          <h5 className="mb-50 lh-5">
          Ensemble, faisons de votre rêve une réalité.
          </h5>
        </div>
      </div>
      <img src="/home1/assets/img/c_line4.png" alt="" className="c-line wow" />
    </section>
  );
}

export default Chat;
