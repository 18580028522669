import React from "react";

function Process() {
  return (
    <section className="tc-process-style2">
      <div className="container">
        <h2 className="fsz-45 fw-500 mb-80 text-center">
          Notre           <span className="sub-font fst-italic color-orange1 fw-400">
          approche
          </span>
        </h2>
        <div className="content">
          <div className="row">
            <div className="col-lg-5">
              <div className="accordion-side wow fadeInUp slow">
                <div className="accordion" id="accordionProcess">
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        <span className="num"> 1 / </span>{" "}
                        <h3> Etude </h3>
                      </button>
                    </div>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionProcess"
                    >
                      <div className="accordion-body">
                        <div className="text">
                          Dans cette première phase cruciale, notre équipe se
                          rend sur le site pour effectuer une analyse
                          approfondie. Nous examinons les caractéristiques du
                          terrain, son environnement immédiat, ainsi que les
                          contraintes et les opportunités qu'il présente. Cette
                          étape nous permet de recueillir des données précieuses
                          qui serviront de base solide pour la conception du
                          projet.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button "
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        <span className="num"> 2 / </span> <h3> Design </h3>
                      </button>
                    </div>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#accordionProcess"
                    >
                      <div className="accordion-body">
                        <div className="text">
                          À partir des informations recueillies lors de l'étude
                          de terrain, notre équipe de concepteurs talentueux
                          prend le relais pour donner vie à votre vision. Nous
                          créons des designs innovants et fonctionnels, en
                          tenant compte de vos besoins, de vos préférences
                          esthétiques et des contraintes techniques. Nos dessins
                          techniques et nos images 3D vous permettront de
                          visualiser le projet dans les moindres détails avant
                          sa réalisation.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                      >
                        <span className="num"> 3 / </span>{" "}
                        <h3> Réalisation </h3>
                      </button>
                    </div>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionProcess"
                    >
                      <div className="accordion-body">
                        <div className="text">
                          Une fois que le design a été approuvé, nous passons à
                          l'étape de l'implémentation. Notre équipe expérimentée
                          coordonne et supervise tous les aspects de la
                          construction, en veillant à ce que le projet soit
                          réalisé selon les normes les plus élevées de qualité
                          et de sécurité. Nous travaillons en étroite
                          collaboration avec des artisans qualifiés pour
                          garantir que chaque détail soit exécuté avec précision
                          et soin.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                      >
                        <span className="num"> 4 / </span> <h3> Livraison </h3>
                      </button>
                    </div>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionProcess"
                    >
                      <div className="accordion-body">
                        <div className="text">
                          Après des mois de travail acharné, nous sommes fiers
                          de vous présenter le résultat final. Notre équipe
                          procède à une inspection minutieuse pour s'assurer que
                          tout est conforme aux spécifications convenues. Une
                          fois que nous sommes certains que le projet répond à
                          vos attentes, nous vous remettons les clés de votre
                          nouvel espace avec un sentiment de satisfaction et
                          d'accomplissement partagé. Votre satisfaction est
                          notre plus grande récompense, et nous sommes ravis de
                          vous avoir accompagné dans ce voyage vers la
                          réalisation de votre vision architecturale.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="imgs">
                <div className="img" data-lag="0.2">
                  <img
                    src="/innerpages/assets/img/process/proc1.jpg"
                    alt=""
                    className="img-cover"
                  />
                  <span className="txt sub-font"> Design </span>
                </div>
                <div className="img" data-lag="0.4">
                  <img
                    src="/innerpages/assets/img/process/proc2.jpg"
                    alt=""
                    className="img-cover"
                  />
                  <span className="txt sub-font"> Livraison </span>
                </div>
                <div className="img" data-lag="0.3">
                  <img
                    src="/innerpages/assets/img/process/proc3.jpg"
                    alt=""
                    className="img-cover"
                  />
                  <span className="txt sub-font"> Etude </span>
                </div>
                <div className="img" data-lag="0.5">
                  <img
                    src="/innerpages/assets/img/process/proc4.jpg"
                    alt=""
                    className="img-cover"
                  />
                  <span className="txt sub-font"> Réalisation </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        src="/innerpages/assets/img/prc_bg.png"
        alt=""
        className="bg"
        data-speed="1.2"
      />
    </section>
  );
}

export default Process;
