import React from "react";
import Loader from "../../components/common/Loader";
import Footer from "../../components/innerpage/Footer";
import Menu from "../../components/innerpage/Menu";
import Navbar2 from "../../components/innerpage/Navbar";
import StartButton from "../../components/home1/StartButton";
import Header from "../../components/innerpage/contact/Header";
// import ContactInfo from "../../components/innerpage/contact/ContactInfo";
import Map from "../../components/innerpage/contact/Map";
// import ContactForm from "../../components/innerpage/contact/ContactForm";
import { Helmet } from "react-helmet";

function ContactPage() {
  return (
    <>
    {/* <img src="/home1/assets/img/contact/photo04.jpg" alt="" /> */}
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href="/innerpages/assets/css/innerpages.css"
        />
        <script src="/common/assets/js/common_js.js"></script>
        <script src="/innerpages/assets/js/innerpages.js"></script>
      </Helmet>
      <body className="inner-pages-style1 contact-pg-style1">
        <Loader />
        <Menu />
        <div className="smooth-scroll-content" id="scrollsmoother-container">
          <Navbar2 />
          <Header />

          <main>
          <div className="container">
        <div className="info">
        </div>
      </div>
            <Map />
            {/* <ContactForm /> */}
          </main>
          <Footer />
        </div>
        <StartButton />
      </body>
    </>
  );
}

export default ContactPage;
