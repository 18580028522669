import React from 'react';

function Services() {
  return (
    <section className="tc-services-style1">
      <div className="content section-padding section-padding-x">
        <div className="container">
          <div className="title mb-80 text-center">
          <h2 className="fsz-45"> Nos services </h2>
          </div>
          <div className="services">
            <div className="row">
              <div className="col col-md-4">
                <a
                  href="#"
                  className="service-card wow fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <div className="icon">
                  <i class="fa-regular fa-comments"></i>
                  </div>
                  <h5 className="fsz-24 mb-20"> Visite conseil </h5>
                  <div className="img">
                    <img
                      src="/home1/assets/img/services/ser11.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50"> 
                  Mon projet d’extension est-il réalisable ? Mes combles sont-ils aménageables ?
                  Combien vont me coûter les travaux de rénovation du bien que je m’apprête à acheter ?
                  Vous vous posez des questions sur votre projet ? Suivez le guide
                  </div>
                  <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span>
                </a>
              </div>
              <div className="col-md-4">
                <a
                  href="#"
                  className="service-card wow fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <div className="icon">
                  <i class="fa-regular fa-file-lines"></i>
                  </div>
                  <h5 className="fsz-24 mb-20">Autorisations administratives</h5>
                  <div className="img">
                    <img
                      src="/home1/assets/img/services/ser22.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                  Il s’agit d’élaborer les dossiers administratifs de demande d’autorisation pour réaliser le projet quel que soit le dossier :
                  <li>Permis de construire</li>
                  <li>Déclaration préalable de travaux</li>
                  <li>Autorisation de travaux</li>
                  <li>Dossier d’enseigne</li>
                  </div>
                  <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span>
                </a>
              </div>
              <div className="col col-md-4">
                <a
                  href="#"
                  className="service-card wow fadeInUp"
                  data-wow-delay="0.6s"
                >
                  <div className="icon">
                  <i class="fa-regular fa-helmet-safety"></i>
                  </div>
                  <h5 className="fsz-24 mb-20"> Préparation de chantier </h5>
                  <div className="img">
                    <img
                      src="/home1/assets/img/services/ser33.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                    Une fois le projet dessiné et finalisé, cette étape consiste à préparer les dossiers de consultation des entreprises : plans et descriptifs détaillé du projet et des quantités.Ces dossiers d’appel d’offre sont envoyés aux entreprises sélectionnées ensemble.

                  </div>
                  <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span>
                </a>
              </div>
              
              
            </div>
            <div className="row">
            <div className="col-md-6">
                <a
                  href="#"
                  className="service-card wow fadeInUp"
                  data-wow-delay="0.8s"
                >
                  <div className="icon">
                  <i class="fa-regular fa-compass"></i>
                  </div>
                  <h5 className="fsz-24 mb-20">
                    Direction de chantier
                  </h5>
                  <div className="img">
                    <img
                      src="/home1/assets/img/services/ser44.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                    Le suivi de chantier est une part importante de notre mission : c’est l’aboutissement de votre projet.
                    Cela intègre entre autres, la coordination des entreprises, les comptes-rendus hebdomadaires, l’exécution avec les artisans des détails dessinés
                  </div>
                  <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span>
                </a>
              </div>
            <div className="col col-md-6">
                <a
                  href="#"
                  className="service-card wow fadeInUp"
                  data-wow-delay="0.8s"
                >
                  <div className="icon">
                  <i class="fa-regular fa-seedling"></i>
                  </div>
                  <h5 className="fsz-24 mb-20">
                    Diagnostic énergétique
                  </h5>
                  <div className="img">
                    <img
                      src="/home1/assets/img/services/ser55.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                  Le diagnostic énergétique est essentiel pour optimiser l'efficacité de votre bâtiment. Il inclut l'évaluation de la performance énergétique, l'identification des pertes de chaleur, et des recommandations pour réduire la consommation. Vous recevrez un rapport détaillé et des solutions pratiques pour améliorer le confort et la durabilité de votre bien.
                  </div>
                  <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="text-center">
            <a
              href="mailto:contact@bouklab-architecture.fr"
              className="butn rounded-pill mt-80 hover-bg-black bg-orange1 text-white"
            >
              <span>
                Contactez nous pour en savoir plus
                <i className="small ms-1 ti-export"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="ser-img">
        <img src="/home1/assets/img/services/ser.jpg" alt="" />
      </div>
    </section>
  );
}

export default Services;
